<template>
  <div id="market">
    <div class="block">
      <MarketCurrenciesSelect
        :from="fromCurrency"
        :to="toCurrency"
        @change="changeCurrencies"
      />
    </div>
    <div class="box support-rtl">
      <h3 class="title is-4 is-flex">
        {{ t('pages.home.latest_rate') }}
      </h3>
      <h5 class="subtitle is-size-7">
        {{ t('pages.home.last_updated_at') }}: {{ lastRateUpatedAt }}
      </h5>
      <div id="exchange-rate">
        <div class="is-size-5">
          1 {{ t(`currencies.${fromCurrency}`) + `(${fromCurrency})` }} =
        </div>
        <div class="is-size-5 has-text-weight-bold is-flex is-flex-wrap-wrap">
          <span class="mr-2">{{ lastRateStr }}</span>
          <span>{{ t(`currencies.${toCurrency}`) + `(${toCurrency})` }}</span>
        </div>
      </div>
    </div>
    <div
      id="currency-calculators"
      class="box"
    >
      <h3 class="title is-4 has-text-left">
        {{ t('pages.home.quick_calculate') }}
      </h3>
      <input
        v-model="inputValue"
        class="number-input input full-width"
        type="number"
        inputmode="decimal"
        placeholder="100"
      >
      <div class="is-size-6">
        {{ t(`currencies.${fromCurrency}`) + `(${fromCurrency})` }}
      </div>
      <div>=</div>
      <div class="is-size-4">
        {{ convertedValue }}
      </div>
      <div class="is-size-6">
        {{ t(`currencies.${toCurrency}`) + `(${toCurrency})` }}
      </div>
    </div>

    <div class="block">
      <h3 class="title is-4 mb-1">
        {{ t('pages.home.trend') }}
      </h3>
      <MarketChart
        :rates="rates"
        :from="fromCurrency"
        :to="toCurrency"
        :change-rate="changeRate"
      />
      <div class="numbers mb-4 support-rtl">
        <div class="number">
          <span class="label">{{ $t('pages.home.high') }}</span>
          <span class="value">{{ highRate }}</span>
        </div>
        <div class="number">
          <span class="label">{{ $t('pages.home.low') }}</span>
          <span class="value">{{ lowRate }}</span>
        </div>
        <div class="number">
          <span class="label">{{ $t('pages.home.average') }}</span>
          <span class="value">{{ avgRate }}</span>
        </div>
        <div class="number">
          <span class="label">{{ $t('pages.home.change') }}</span>
          <span class="value">{{ changeRate }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, computed } from "vue";
import { useI18n } from 'vue-i18n';
import MarketCurrenciesSelect from "../components/MarketCurrenciesSelect.vue"
import MarketChart from "../components/MarketChart.vue"
import type { Rate } from '../lib/types.d';

const i18nLocale = useI18n();
const t = i18nLocale.t;

const props = defineProps({
  from: {
    type: String,
    required: true
  },
  to: {
    type: String,
    required: true
  },
  rates: {
    type: Array<Rate>,
    required: true
  }
});

const fromCurrency = ref(props.from);
const toCurrency = ref(props.to);
const emit = defineEmits(['currencies-changed']);
const changeCurrencies = (val) => {
  fromCurrency.value = val.from;
  toCurrency.value = val.to;

  emit('currencies-changed', val);
}
watchEffect(() => {
  fromCurrency.value = props.from;
  toCurrency.value = props.to;
})

const lastRateUpatedAt = computed(() => {
  return new Date(props.rates[props.rates.length - 1].timestamp).toLocaleString(i18nLocale.locale.value);
});
const lastRate = computed(() => {
  return  props.rates[props.rates.length - 1].rates[toCurrency.value] / props.rates[props.rates.length - 1].rates[fromCurrency.value];
});
const lastRateStr = computed(() => {
  const fractionPart = lastRate.value.toFixed(4).slice(-4);
  const integerPart = Math.trunc(lastRate.value).toLocaleString('en-US');
  return `${integerPart}.${fractionPart}`;
});
const inputValue = ref('');
const convertedValue = computed(() => {
  const amount = typeof inputValue.value === 'number'? inputValue.value : 100;
  return (amount * lastRate.value).toLocaleString('en-US');
});



const highRate = computed(() => {
  const rate = Math.max(...props.rates.map((rate) => rate.rates[toCurrency.value] / rate.rates[fromCurrency.value]));
  return rate.toFixed(4);
});
const lowRate = computed(() => {
  const rate = Math.min(...props.rates.map((rate) => rate.rates[toCurrency.value] / rate.rates[fromCurrency.value]));
  return rate.toFixed(4);
});
const avgRate = computed(() => {
  const rate = props.rates.reduce((acc, rate) => {
    return acc + rate.rates[toCurrency.value] / rate.rates[fromCurrency.value];
  }, 0) / props.rates.length;
  return rate.toFixed(4);
});
const changeRate = computed(() => {
  const firstRate = props.rates[0].rates[toCurrency.value] / props.rates[0].rates[fromCurrency.value];
  const rate = ((lastRate.value - firstRate) / firstRate) * 100;
  return rate.toFixed(2);
});
</script>

<style scoped>
#currency-calculators {
  input {
    text-align: right;
  }
  text-align: right;
}

.numbers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .number {
    justify-self: center;
    text-align: center;
  }
}
</style>