import { Application } from "@hotwired/stimulus"
import MarketController from "../controllers/market_controller"
import { appLocale, getI18nParams } from '../lib/getI18nParams'
import { I18n } from "i18n-js";

// Example: Load Rails libraries in Vite.
window.Stimulus = Application.start()
Stimulus.register("market", MarketController)

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

document.addEventListener('DOMContentLoaded', async () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  // Add a click event on each of them
  $navbarBurgers.forEach( el => {
    el.addEventListener('click', () => {

      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');

    });
  });

  const messages = (await getI18nParams()).messages
  window.I18n = new I18n(messages);
  window.I18n.locale = appLocale;
});
