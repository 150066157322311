<template>
  <div id="currencies-convert">
    <BaseCurrencySelect
      class="currency-select"
      :value="baseCurrency"
      @change="changeBaseCurrency"
    />
    <button
      class="button has-background-text is-outlined"
      @click="switchCurrencies"
    >
      <i class="fas fa-arrow-right" />
    </button>
    <BaseCurrencySelect
      class="currency-select"
      :value="targetCurrency"
      @change="changeTargetCurrency"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, watchEffect } from 'vue';
import BaseCurrencySelect from './BaseCurrencySelect.vue';

const props = defineProps({
  from: {
    type: String,
    required: true,
  },
  to: {
    type: String,
    required: true,
  },
});

const baseCurrency = ref(props.from);
const targetCurrency = ref(props.to);

const emit = defineEmits(['change']);
const emitCurrenciesChange = () => {
  emit('change', {
    from: baseCurrency.value,
    to: targetCurrency.value,
  });
};
const changeBaseCurrency = (currency) => {
  baseCurrency.value = currency;
  emitCurrenciesChange();
};
const changeTargetCurrency = (currency) => {
  targetCurrency.value = currency;
  emitCurrenciesChange();
};
const switchCurrencies = () => {
  const temp = baseCurrency.value;
  baseCurrency.value = targetCurrency.value;
  targetCurrency.value = temp;
  emitCurrenciesChange();
};

watchEffect(() => {
  baseCurrency.value = props.from;
  targetCurrency.value = props.to;
});

</script>

<style lang="scss" scoped>
#currencies-convert {
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  gap: 10px;
}

button {
  height: 36px;
}

.currency-select {
  justify-self: stretch;
}
</style>