
import { Controller } from "@hotwired/stimulus"
import { createApp, reactive, h } from 'vue'
import { createI18n } from 'vue-i18n'
import { getI18nParams } from '../lib/getI18nParams'
import vSelect from "vue-select";
import Market from "../pages/Market.vue"
import VueApexCharts from "vue3-apexcharts";


export default class extends Controller {
  static targets = [ "marketApp", "description" ]
  static values = {
    baseCurrency: String,
    targetCurrency: String,
    rates: Array
  }

  async connect() {
    this.updateURL(true)

    // create vue app
    const i18n = createI18n(await getI18nParams())

    this.props = reactive({
      from: this.baseCurrencyValue,
      to: this.targetCurrencyValue,
      rates: this.ratesValue,
      onCurrenciesChanged: (val) => {
        this.baseCurrencyValue = val.from
        this.targetCurrencyValue = val.to
      }
    })
    const app = createApp({
      render: () => h(Market, this.props)
    })
    app.use(i18n)
    app.use(VueApexCharts)
    app.component("VSelect", vSelect)
    app.mount(this.marketAppTarget)

    addEventListener('popstate', () => {
      const url = new URL(window.location)
      this.baseCurrencyValue = url.searchParams.get('from')
      this.targetCurrencyValue = url.searchParams.get('to')
      this.props.from = this.baseCurrencyValue
      this.props.to = this.targetCurrencyValue
    })
  }

  baseCurrencyValueChanged(_, previousVal) {
    if (!previousVal) { return }

    document.cookie = `from=${this.baseCurrencyValue}; SameSite=Lax`
    this.refreshText()
    this.updateURL()
  }

  targetCurrencyValueChanged(_, previousVal) {
    if (!previousVal) { return }

    document.cookie = `to=${this.targetCurrencyValue}; SameSite=Lax`
    this.refreshText()
    this.updateURL()
  }

  refreshText() {
    this.descriptionTarget.innerHTML = window.I18n.t('pages.home.description', {
      from_currency: window.I18n.t(`currencies.${this.baseCurrencyValue}`),
      from: this.baseCurrencyValue,
      to_currency: window.I18n.t(`currencies.${this.targetCurrencyValue}`),
      to: this.targetCurrencyValue
    })
  }

  updateURL(replace = false) {
    const url = new URL(window.location)
    url.searchParams.set('from', this.baseCurrencyValue)
    url.searchParams.set('to', this.targetCurrencyValue)
    if (replace) {
      window.history.replaceState({}, '', url)
    } else {
      window.history.pushState({}, '', url)
    }
  }
}
