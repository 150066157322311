<template>
  <div
    class="currency-select"
    :class="{small: size === 'small', medium: size === 'medium', large: size === 'large'}"
  >
    <VSelect
      :model-value="selectedCurrency"
      class="currency-selector"
      :disabled="disabled"
      :options="currencyOptions"
      :filter="filterFunction"
      :clearable="false"
      @option:selected="onSelected"
    >
      <template #selected-option="{ code, icon }">
        <div class="currency-option">
          <BaseCurrencyIcon
            :icon-name="icon"
            size="small"
          />
          <span class="code">{{ code }}</span>
        </div>
      </template>
      <template #option="{ code, icon }">
        <div class="currency-option">
          <BaseCurrencyIcon
            :icon-name="icon"
            size="small"
          />
          <span class="code">{{ code }}</span>
        </div>
      </template>
    </VSelect>
  </div>
</template>

<script setup>
import BaseCurrencyIcon from './BaseCurrencyIcon.vue';
import { computed, ref } from 'vue';
import currencies from '../data/currencies';
import "vue-select/dist/vue-select.css";

const props = defineProps({
  value: {
    type: String,
    default: () => 'USD'
  },
  disabled: Boolean,
  size: {
    type: String,
    default: () => 'medium'
  }
});

// Options
const availableCurrencies = computed(() => {
  return Object.values(currencies).filter((c) => !c.crypto).sort((a, b) => {
    return a.code.localeCompare(b.code)
  });
});
const currencyOptions = computed(() => availableCurrencies.value.map((currency) => ({
  code: currency.code,
  icon: currency.icon ?? currency.code,
})));
const filterFunction = (options, query) => {
  return options.filter(option => option.code.toLowerCase().includes(query.toLowerCase()));
};

// Selected currency
const selectedCurrencyCode = computed(() => props.value);
const selectedCurrency = computed(() => availableCurrencies.value.find(c => c.code === selectedCurrencyCode.value));

// Events
const emit = defineEmits(['change']);
const onSelected = (val) => {
  emit('change', val.code);
};
</script>

<style lang="scss" scoped>
.currency-select {
  display: flex;
  align-items: center;
  gap: 5px;
}

select {
  border: none;
  border-radius: 0;
  background: transparent;
}

.currency-select.small {
  gap: 2px;
}
.currency-selector {
  width: 100%;
}
.currency-option {
  display: flex;
  align-items: center;
  gap: 5px;
}
.code {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}
</style>