<template>
  <div id="market-chart">
    <div class="chart">
      <apexchart
        width="100%"
        :options="options"
        :series="series"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue';

const props = defineProps({
  rates: {
    type: Object,
    required: true
  },
  from: {
    type: String,
    required: true
  },
  to: {
    type: String,
    required: true
  },
});

const dataSeries = computed(() => {
  return props.rates.map((rate) => {
    const date = new Date(rate.timestamp);
    return {
      x: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      y: (rate.rates[props.to] / rate.rates[props.from]).toFixed(4)
    }
  });
});

const chartColor = computed(() => {
  if (props.changeRate === 0) {
    return '#aaa';
  }
  return props.changeRate > 0 ? '#5dc456' : '#ff3860';
});

const options = computed(() => ({
  chart: {
    id: 'vuechart-example',
    type: 'area',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: 1,
  },
  tooltip: {
    enabled: true,
    custom: function({series, seriesIndex, dataPointIndex, w}) {
      const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];
      const date = new Date(timestamp);
      const formattedDate = date.getFullYear() + '-' +
                            String(date.getMonth() + 1).padStart(2, '0') + '-' +
                            String(date.getDate()).padStart(2, '0');
      return '<div class="has-background-dark p-1">' +
        '<div class="has-text-light">' + formattedDate + '</div>' +
        '<span class="has-text-light">' + series[seriesIndex][dataPointIndex] + '</span>' +
      '</div>'
    }

  },
  colors: [chartColor.value],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.6,
      opacityTo: 1,
      stops: [0,90,100]
    }
  },
  grid: {
    show: false
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: true,
      datetimeFormatter: {
          year: 'yyyy',
          month: "yyyy/MM",
          day: 'dd MM',
          hour: 'HH:mm',
          minute: 'HH:mm:ss',
          second: 'HH:mm:ss',
      },
    },
    axisBorder: {
      show: true
    },
    axisTicks: {
      show: true
    },
    tooltip: {
      enabled: false,
    }
  },
  yaxis: {
    labels: {
      show: true
    },
    axisBorder: {
      show: true
    },
    axisTicks: {
      show: true
    }
  }
}))
const series = computed(() => ([{
  name: 'rate',
  data: dataSeries.value
}]))
</script>

<style scoped>

</style>